
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    IonButtons,
    menuController,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiRichieste from "@/services/richieste";
import apiBancaOre from "@/services/banca_ore";

import ModalNuovaRichiesta from "@/components/richieste/ModalNuovaRichiesta.vue";
import RichiestaDetail from "@/components/richieste/RichiestaDetail.vue";
/* import { dbRichieste, getRichieste } from "../services/localbase"; */

export default {
    name: "Richieste",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);
        const userID = JSON.parse(localStorage.getItem("userInfoAssitech")).users_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const dataRichiesta = computed(() => {
            return (dataInizio, dataFine) => {
                const inizio = moment(dataInizio).format("DD/MM/YYYY");
                const fine = moment(dataFine).format("DD/MM/YYYY");
                if (!moment(dataFine).isValid()) {
                    return `Dal ${inizio}`;
                } else if (inizio == fine) {
                    return `${inizio}`;
                } else {
                    return `Dal ${inizio} al ${fine}`;
                }
            };
        });

        const orarioRichiesta = computed(() => {
            return (richiesta) => {
                if (richiesta.richieste_tipologia == 1) {
                    return `Dalle ${richiesta.richieste_ora_inizio} alle ${richiesta.richieste_ora_fine}`;
                }
            };
        });

        /**
         * Get all richieste
         */
        async function loadRichieste() {
            loading.value = true;
            try {
                richieste.value = await apiRichieste.getRichieste(dipendenteID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei dati", "toast_danger");
            }
            loading.value = false;
        }
        loadRichieste();

        /**
         *
         * Get all RICHIESTE for the logged user in localDB otherwise from server
         *
         */
        /*async function loadRichieste() {
      loading.value = true;
      //const localClienti = JSON.parse(localStorage.getItem("clienti"));
      const richiesteDB = await getRichieste();
      if (richiesteDB.length != 0) {
        richieste.value = richiesteDB;
        //console.log("richieste da locale: ", richieste.value);
        loading.value = false;
      } else {
        loading.value = true;
        //console.log("richieste da remoto");
        apiRichieste
          .getRichieste(dipendenteID)
          .then((response) => {
            richieste.value = response;
          })
          .catch((error) => {
            openToast("Errore durante la richiesta dei dati", "danger");
          })
          .finally(() => {
            loading.value = false;
          });
      }
    }*/

        //loadRichieste();

        //Insert new richiesta in dbRichieste (LOCAL)
        /*     async function addRichiesta(richiesta) {
      await dbRichieste
        .collection("richieste")
        .add(richiesta, richiesta.richieste_id)
        .then((response) => {
          //console.log(response);
          richieste.value = [response.data.data, ...richieste.value];
        })
        .catch((error) => {
          console.error("There was an error.");
        });
    } */

        /**
         * Get all banca ora records
         */
        const banca_ore = ref([]);
        async function loadBancaOre() {
            loading.value = true;
            try {
                banca_ore.value = await apiBancaOre.getOre(dipendenteID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle ore disponibili", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * ! Calculate banca ore balance
         */
        const calcolaSaldo = computed(() => {
            let balance = 0;
            banca_ore.value.forEach((element) => {
                //onsole.log(element);
                switch (element.banca_ore_movimento) {
                    case "1": //Aggiunto
                        balance += parseFloat(element.banca_ore_hours);
                        break;
                    case "2": ///Cancellata
                        balance += 0;
                        break;
                    case "3": //Pagata
                    case "4": //Utilizzata
                        balance -= parseFloat(element.banca_ore_hours);
                        break;
                    default:
                        balance = 0;
                        break;
                }
            });

            return balance.toFixed(2);
        });

        /**
         * Open modal to create new presenza
         */
        async function openModal() {
            const modal = await modalController.create({
                component: ModalNuovaRichiesta,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    richieste.value = [detail.data, ...richieste.value];
                    openToast("Nuova richiesta inserita", "toast_success");
                }
            });
            return modal.present();
        }

        //Set correct background for richieste status
        const statoRichiesta = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //attesa approvazione
                    className = "badge_warning";
                } else if (statusId == 2) {
                    //approvato
                    className = "badge_success";
                } else if (statusId == 3) {
                    //rifiutato
                    className = "badge_danger";
                }
                return className;
            };
        });

        /**
         * Passing id to retrive detail in RichiestaDetail page
         */
        async function openDetailModal(richiestaDetail) {
            //console.log(richiestaDetail);
            const modal = await modalController.create({
                component: RichiestaDetail,
                componentProps: {
                    data: richiestaDetail,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    richieste.value = [detail.data, ...richieste.value];
                    openToast("Nuova richiesta inserita", "toast_success");
                }
            });
            return modal.present();
        }
        function openDetail(id: string) {
            router.push(`tab3/${id}`);
        }

        onMounted(() => {
            //GET RICHIESTE DATA
            loadRichieste();
            loadBancaOre();
        });

        /* Ad ogni accesso alla pagina richiedo i dati della bbanca ore perchè potrebbe essere cambiato il saldo */
        onIonViewWillEnter(() => {
            loadBancaOre();
        });

        return {
            loading,
            add,
            refresh,
            openDetail,
            //getRichieste,
            openModal,
            richieste,
            statoRichiesta,
            //New field
            loadRichieste,
            openDetailModal,
            dataRichiesta,
            openMenu,
            menu,
            orarioRichiesta,
            calcolaSaldo,
        };
    },
};
